import { addDays, addMonths } from "date-fns";
import { useMemo } from "react";
import { Eventlog, Node, WorkHistory } from "../../../../../../API";
import { ListResult } from "../../../../../hooks/list-result";
import { useNodesEventlog } from "../../../../../hooks/use-nodes-eventlog";
import { useNodesWorkHistory } from "../../../../../hooks/use-nodes-work-history";
import { NodeHistoryItem } from "./node-history-item";

export function useNodeHistoryData(
    targetNodes: readonly Node[],
): ListResult<NodeHistoryItem> {
    const now = useMemo(() => new Date(), []);
    const requestOptions = useMemo<useNodesEventlog.Options>(
        () => ({ time: { gt: addMonths(now, -2).toISOString() } }),
        [now],
    );
    const eventlogs = useNodesEventlog(targetNodes, requestOptions);
    const memos = useNodesWorkHistory(targetNodes, requestOptions);
    const inspects: MaintenanceItem[] = useMemo(
        () => [
            {
                time: addDays(now, -14),
                nodeName: "高圧盤",
                datumName: "異臭",
                value: "有",
                comment: "何かが焦げているようながする",
            },
            {
                time: addDays(now, -21),
                nodeName: "高圧盤 母線連結ＶＣＢ",
                datumName: "赤判定",
                value: "切",
                comment: "ランプが消えていた",
            },
            {
                time: addDays(now, -28),
                nodeName: "高圧盤 IPMAT III S",
                datumName: "",
                value: "切",
                comment: "ランプが消えていた",
            },
        ],
        [now],
    );

    return useMemo<ListResult<NodeHistoryItem>>(
        () => ({
            data: mergeNodeHistory(
                targetNodes,
                eventlogs.data,
                memos.data,
                inspects,
            ),
            error: eventlogs.error ?? memos.error,
            loading: eventlogs.loading || memos.loading,
        }),
        [eventlogs, inspects, memos, targetNodes],
    );
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

interface MaintenanceItem {
    time: Date;
    nodeName: string;
    datumName: string;
    value: string;
    comment: string;
}

/**
 * イベントログ、作業メモ、点検記録をマージする
 * @param nodes ノードの配列
 * @param eventlogs イベントログの配列
 * @param memos 作業メモの配列
 * @param inspects 点検記録の配列
 * @returns
 */
function mergeNodeHistory(
    nodes: readonly Node[],
    eventlogs: readonly Eventlog[],
    memos: readonly WorkHistory[],
    inspects: readonly MaintenanceItem[],
): NodeHistoryItem[] {
    return [
        ...eventlogs.map((e) => convertEventlogToNodeHistoryItem(e, nodes)),
        ...memos.map((m) => convertMemoToNodeHistoryItem(m, nodes)),
        ...inspects.map(convertInspectToNodeHistoryItem),
    ].sort(compareByTime);
}

function convertEventlogToNodeHistoryItem(
    eventlog: Eventlog,
    nodes: readonly Node[],
): NodeHistoryItem {
    return {
        id: `eventlog-${eventlog.id}`,
        content: `${eventlog.datumName} が ${eventlog.event} になりました。`,
        nodeName:
            nodes.find((node) => node.id === eventlog.nodeId)?.name ??
            eventlog.nodeId,
        time: new Date(eventlog.time),
        type: eventlog.type,
    };
}

function convertInspectToNodeHistoryItem(
    { comment, datumName, nodeName, time, value }: MaintenanceItem,
    index: number,
): NodeHistoryItem {
    return {
        id: `maintenance-${index}`,
        content: `${datumName} は ${value} でした。${comment}`,
        nodeName,
        time,
        type: "Maintenance",
    };
}

function convertMemoToNodeHistoryItem(
    { nodeId, time, workSummary, id }: WorkHistory,
    nodes: readonly Node[],
): NodeHistoryItem {
    return {
        id: `memo-${id}`,
        content: workSummary,
        nodeName: nodes.find((node) => node.id === nodeId)?.name ?? nodeId,
        time: new Date(time),
        type: "Memo",
    };
}

function compareByTime(a: NodeHistoryItem, b: NodeHistoryItem): number {
    return b.time.getTime() - a.time.getTime();
}
